var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _vm.src != ""
      ? _c("audio", {
          style: { width: _vm.width },
          attrs: { src: _vm.src, autoplay: _vm.autoplay, controls: "" }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }