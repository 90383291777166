<template>
  <div class="text-center">
    <audio 
      v-if="src != ''"
      :src="src"
      :autoplay="autoplay"
      :style="{ width: width }"
      controls />
  </div>
</template>

<script>
  export default {
    name: 'AudioPlayer',
    data () {
      return {
      }
    },
    props: {
      src: { type: String, default: '' },
      autoplay: { type: Boolean, default: true },
      width: {
        type: String,
        default: '300px'
      }
    },
  }
</script>

<style lang="scss" scoped>
  audio:focus {
    outline: none;
  }
</style>
